<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addEnquiriesModal' hide-footer>
        <addEnquiries @closeAddEnquiries='toggleAddEnquiries()' @addEnquiriesSuccess='addEnquiriesSuccess()'></addEnquiries>
      </b-modal>
      <b-modal ref='editEnquiriesModal' hide-footer>
        <editEnquiries
          :editingEnquiries='currentEnquiries'
          @closeEditEnquiries='toggleEditEnquiries()'
          @editEnquiriesSuccess='editEnquiriesSuccess()'
        ></editEnquiries>
      </b-modal>
      <div class='row'>
        <h2>Enquiries</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddEnquiries()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addEnquiries from '../../components/addEnquiries';
import editEnquiries from '../../components/editEnquiries';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'Fullname',
                field: 'Fullname',
                sort: 'asc',
              },
              {
                label: 'EmailAddress',
                field: 'EmailAddress',
                sort: 'asc',
              },
              {
                label: 'Cellphone',
                field: 'Cellphone',
                sort: 'asc',
              },
              {
                label: 'PreferredContactMethod',
                field: 'PreferredContactMethod',
                sort: 'asc',
              },
              {
                label: 'EnquiryType',
                field: 'EnquiryType',
                sort: 'asc',
              },
              {
                label: 'EnquiryDate',
                field: 'EnquiryDate',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addEnquiriesOpen: false,
      editEnquiriesOpen: false,
      currentEnquiries: {}
    };
  },
  components: {
    addEnquiries,
    editEnquiries,
    Datatable,
  },
  created: function() {
    this.getEnquiries();
    this.addEnquiriesOpen = false;
    this.editEnquiriesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getEnquiries() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getEnquiries', payload);
    },
    editItem(Enquiries) {
      this.toggleEditEnquiries();
      this.currentEnquiries = Enquiries;
    },
    toggleAddEnquiries() {
      if(this.addEnquiriesOpen)
      {
        this.$refs.addEnquiriesModal.hide()
      }
      else{
        this.$refs.addEnquiriesModal.show()
      }
      this.addEnquiriesOpen = !this.addEnquiriesOpen;
    },
    addEnquiriesSuccess() {
      this.toggleAddEnquiries();
      miniToastr['success']('Enquiries Added', 'Success', 1000, null);
      this.getEnquiries();
    },
    toggleEditEnquiries() {
      if(this.editEnquiriesOpen)
      {
        this.$refs.editEnquiriesModal.hide()
      }
      else{
        this.$refs.editEnquiriesModal.show()
      }
      this.editEnquiriesOpen = !this.editEnquiriesOpen;
    },
    editEnquiriesSuccess() {
      this.toggleEditEnquiries();
      miniToastr['success']('Enquiries Edited', 'Success', 1000, null);
      this.getEnquiries();
    }
  }
};
</script>
<style scoped>
.EnquiriesThumbnail {
  height: 50px;
}
</style>
