<template>
  <div class='container'>
    <h2>Edit Enquiries</h2>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    
    <button @click='deleteEnquiries()' type='button' class='btn btn-danger'>Delete</button>
	
	<div class='row'>
<div class='col-md-4'>
   <label class for='Fullname'>Fullname</label>
   <input
     class='form-control'
     id='Fullname'
     name='Fullname'
     v-model='editingEnquiries.Fullname'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='EmailAddress'>EmailAddress</label>
   <input
     class='form-control'
     id='EmailAddress'
     name='EmailAddress'
     v-model='editingEnquiries.EmailAddress'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='Cellphone'>Cellphone</label>
   <input
     class='form-control'
     id='Cellphone'
     name='Cellphone'
     v-model='editingEnquiries.Cellphone'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='PreferredContactMethod'>PreferredContactMethod</label>
   <input
     class='form-control'
     id='PreferredContactMethod'
     name='PreferredContactMethod'
     v-model='editingEnquiries.PreferredContactMethod'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='EnquiryType'>EnquiryType</label>
   <input
     class='form-control'
     id='EnquiryType'
     name='EnquiryType'
     v-model='editingEnquiries.EnquiryType'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='EnquiryDate'>EnquiryDate</label>
   <input
     class='form-control'
     id='EnquiryDate'
     name='EnquiryDate'
     v-model='editingEnquiries.EnquiryDate'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
<div class='col-md-4'>
   <label class for='LastUpdated'>LastUpdated</label>
   <input
     class='form-control'
     id='LastUpdated'
     name='LastUpdated'
     v-model='editingEnquiries.LastUpdated'
     placeholder=' '
     tabindex='0'
     type='text'
   >
	
 </div>
  </div>
    <br/>
    <button @click='save()' type='button' class='btn btn-success'>Save</button>
    <button @click='toggleView()' type='button' class='btn btn-warning'>Cancel</button>
    <br/>
    <br/>
    </div>
    
</template>
<script>
import _ from 'lodash'; 
import FileUploader from '@/components/custom/FileUploader.vue'; 
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
 
export default { 
  name: 'EditEnquiries', 
 
  data() { 
    return { 
      imageBytes: {}, 
      selectedFile: {}, 
      originalData: {}, 
      modifiedData: {}, 
        uploadedImageData: {}, 
        uploadedImageUrl: null, 
      editingImage: false 
    }; 
  }, 
  components: { 
    FileUploader 
  }, 
  created() { 
    this.originalData = JSON.parse(JSON.stringify(this.editingEnquiries)); 
    this.ImageBytes = this.editingEnquiries.Image; 
  }, 
 
  props: ['editingEnquiries'], 
 
  methods: { 
    toggleView: function() { 
      this.$emit('closeEditEnquiries'); 
    }, 
 
    save: function() { 
    var data = JSON.parse(JSON.stringify(this.editingEnquiries));
      var payload = {
          data: data,
          image: this.uploadedImageData,
          success: response => {
            this.$emit('editEnquiriesSuccess') 
          },
          error: error => { miniToastr['error'](error, 'Error', 1000, null)}
        }
        this.$store.dispatch('editEnquiries', payload);
    }, 
	updateImageData: function(value) { 
      this.uploadedImageData = value; 
    }, 
	updateImageUrl: function(value) {  
      this.uploadedImageUrl = value; 
    }, 
    editImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    updateImage: function() { 
      this.editingImage = !this.editingImage; 
    }, 
    getImageSource(data) {
      if(this.uploadedImageUrl)
      {
        return this.uploadedImageUrl;
      }
        if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
            return this.$store.state.cdnUrl + data.Image;
        }
        else {
            return data.Image;
        }
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    deleteEnquiries: function() { 
     var payload = {
      data: this.editingEnquiries,
      success: response => {
        this.$emit('editEnquiriesSuccess') 
      },
      error: error => { miniToastr['error'](error, 'Error', 1000, null)}
    }
    this.$store.dispatch('deleteEnquiries', payload);
    } 
  } 
}; 
</script> 
<style scoped> 
.previewImage { 
  max-height: 100px; 
} 
</style> 
